//====================================================
//  Function: Parallax
//====================================================

function componentParallax() {
  if ($('.js-parallax').length) {
    var parallaxImages = document.querySelectorAll('.js-parallax')
    new simpleParallax(parallaxImages, {
      delay: 0.8,
      scale: 1.1,
    })
  }
}
