//====================================================
//  Function: Slider
//====================================================
function componentSwiper() {
  slideshowSlider()
}

function slideshowSlider() {
  if ($('.js-slider-slideshow').length) {
    $('.js-slider-slideshow').each(function () {
      var inst = $(this)
      var delay = inst.attr('data-delay')
      var reverse = inst.attr('data-reverse')
      if (reverse == 'true') {
        var reverseValue = true
      } else {
        var reverseValue = false
      }

      var mySwiper = new Swiper(inst, {
        spaceBetween: 0,
        // lazy: true,
        loop: true,
        slidesPerView: 'auto',
        centeredSlides: true,
        speed: 600,
        // freeMode: true,
        slideToClickedSlide: true,
        autoplay: {
          delay: delay,
          reverseDirection: reverseValue,
          disableOnInteraction: false,
        },
      })
    })
  }
}
