//====================================================
//  Function: Range slider
//====================================================
function componentRangeSlider() {
  if ($('.js-range-block').length) {
    $('.js-range-block').each(function () {
      var inst = $(this)
      var rangeSlider
      var thisTabs = $('.js-tabs-group')
      var $range = inst.find('.js-range-slider-handz'),
        from,
        to

      var updateValues = function (id) {
        id++
        thisTabs
          .find('.js-tabs-content .js-tab-content-item.' + classIsActive + '')
          .removeClass(classIsActive)
        thisTabs
          .find(
            '.js-tabs-content .js-tab-content-item[data-id="tab-' + id + '"]',
          )
          .addClass(classIsActive)
        // setTimeout(() => {
        //   thisTabs
        //     .find('.js-tabs-content .js-tab-content-item.is-hidding')
        //     .removeClass('is-hidding')
        // }, 600)
        // $from.val(from)
      }

      $range.ionRangeSlider({
        type: 'single',
        step: 1,
        from: 2,
        grid: true,
        values: ['1', '2', '3', '4', '5'],
        force_edges: false,
        hide_min_max: true,
        postfix: ' €',
        hide_from_to: true,
        onChange: function (data) {
          from = data.from
          console.log(from)
          // to = data.to
          updateValues(from)
        },
      })

      inst = $range.data('ionRangeSlider')

      $(document).on('click', '.irs-grid-text', function (event) {
        var value = $(this).attr('class')
        var valueLastChar = value.substr(value.length - 1)

        inst.update({
          from: valueLastChar,
        })
        updateValues(valueLastChar)
      })
    })
  }
}
