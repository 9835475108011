//====================================================
//  Function: Animation
//====================================================
function componentAnimation() {
  // Hero Image

  if ($window.width() > bpSmall) {
    var heroOffset = -42
  } else {
    var heroOffset = -22
  }

  if ($window.height() > 1200) {
    var heroOffset = -10
  }

  gsap.from('.js-hero-image', {
    scrollTrigger: {
      trigger: '.section--hero',
      start: 'top 50px', // when the top of the trigger hits the top of the viewport
      end: '+=400', // end after scrolling 500px beyond the start
      scrub: 1, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
    },
    xPercent: heroOffset,
  })

  if ($window.height() > 1200) {
    var listY = 'center'
  } else {
    var listY = '550px'
  }

  // List items
  let tl = gsap.timeline({
    scrollTrigger: {
      trigger: '.list-style',
      start: 'top ' + listY, // when the top of the trigger hits the top of the viewport
      end: '+=300', // end after scrolling 500px beyond the start
      scrub: 1, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
    },
  })

  if ($window.width() > bpSmall) {
    var listOptions = {
      opacity: '0',
      transform: 'translate3d(0, 30px , 0)',
    }
  } else {
    var listOptions = {
      opacity: '0',
    }
  }

  tl.from('.list-style-item-1', listOptions)
    .from('.list-style-item-2', listOptions)
    .from('.list-style-item-3', listOptions)
    .from('.list-style-item-4', listOptions)
    .from('.list-style-item-5', listOptions)
    .from('.list-style-item-6', listOptions)

  if ($window.height() > 1200) {
    var slideshowBigY = 'center'
  } else {
    var slideshowBigY = '600px'
  }

  // SlideShow Big
  if ($window.width() > bpSmall) {
    gsap.from('.js-slideshow-big', {
      scrollTrigger: {
        trigger: '.js-slideshow-big',
        start: 'top ' + slideshowBigY, // when the top of the trigger hits the top of the viewport
        end: '+=100', // end after scrolling 500px beyond the start
        scrub: 1, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
      },
      y: '100px',
      opacity: 0,
    })
  }

  if ($window.height() > 1200) {
    var featuresY = 'center'
  } else {
    var featuresY = '250px'
  }

  // Features hand
  let features = gsap.timeline({
    defaults: {
      duration: 2,
      // ease: "none"
    },
    smoothChildTiming: true,
    scrollTrigger: {
      trigger: '.section--offer-1',
      start: 'top ' + featuresY, // when the top of the trigger hits the top of the viewport
      end: '+=400', // end after scrolling 500px beyond the start
      scrub: 1, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
    },
  })

  if ($window.width() > bpSmall) {
    features
      .from('.js-img--features', {
        opacity: '0.8',
        transform: 'translate3d(0, 150px , 0) scale(0.8)',
        duration: 6,
      })
      .from('.hand-features__item--1', {
        opacity: '0',
        transform: 'scale(0)',
        delay: 1,
      })
      .from('.hand-features__item--4', { opacity: '0', transform: 'scale(0)' })
      .from('.hand-features__item--2', { opacity: '0', transform: 'scale(0)' })
      .from('.hand-features__item--5', { opacity: '0', transform: 'scale(0)' })
      .from('.hand-features__item--3', { opacity: '0', transform: 'scale(0)' })
      .from('.hand-features__item--6', { opacity: '0', transform: 'scale(0)' })
  } else {
    features
      .from('.hand-features__item--1', {
        opacity: '0',
      })
      .from('.hand-features__item--2', { opacity: '0' })
      .from('.hand-features__item--3', { opacity: '0' })
      .from('.hand-features__item--4', { opacity: '0' })
      .from('.hand-features__item--5', { opacity: '0' })
      .from('.hand-features__item--6', { opacity: '0' })
  }

  if ($window.height() > 1200) {
    var rangeY = 'center'
  } else {
    var rangeY = '400px'
  }

  // Hand Range slider
  let handRange = gsap.timeline({
    defaults: {
      duration: 2,
    },
    scrollTrigger: {
      trigger: '.section--offer-2',
      start: 'top ' + rangeY, // when the top of the trigger hits the top of the viewport
      end: '+=300', // end after scrolling 500px beyond the start
      scrub: 1, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
    },
  })

  if ($window.width() > bpSmall) {
    handRange.from('.js-hand-choose', { xPercent: 20 }).from('.js-hand-range', {
      opacity: '0',
      transform: 'translate3d(0, 30px , 0)',
    })
  } else {
    handRange
      .from('.js-hand-range', {
        opacity: '0',
        transform: 'translate3d(0, 30px , 0)',
      })
      .from('.js-hand-choose', {
        opacity: '0',
        transform: 'translate3d(0, 30px , 0)',
      })
  }

  if ($window.height() > 1200) {
    var offerInfo = 'center'
  } else {
    var offerInfo = '800px'
  }

  gsap.from('.section--offer-info', {
    scrollTrigger: {
      trigger: '.js-hand-top-bottom-text',
      start: 'top ' + offerInfo, // when the top of the trigger hits the top of the viewport
      end: '+=400', // end after scrolling 500px beyond the start
      scrub: 1, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
    },
    opacity: '0',
    transform: 'translate3d(0, 30px , 0)',
  })

  if ($window.height() > 1200) {
    var hands = 'center'
  } else {
    var hands = '1000px'
  }

  // Hand Top Bottom
  gsap.from('.js-hand-top', {
    scrollTrigger: {
      trigger: '.section--offer-info-ext',
      start: 'top ' + hands, // when the top of the trigger hits the top of the viewport
      end: '+=800', // end after scrolling 500px beyond the start
      scrub: 1, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
    },
    transform: 'translate3d(-10%, -10% , 0)',
    duration: 30,
  })

  if ($window.height() > 1200) {
    var handsB = 'center'
  } else {
    var handsB = '1000px'
  }

  gsap.from('.js-hand-bottom', {
    scrollTrigger: {
      trigger: '.section--offer-info-ext',
      start: 'top ' + handsB, // when the top of the trigger hits the top of the viewport
      end: '+=800', // end after scrolling 500px beyond the start
      scrub: 1, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
    },
    transform: 'translate3d(10%, 10% , 0)',
    duration: 20,
  })

  // if ($window.width() > bpSmall) {
  //   handTopBottom
  //     .from('.js-hand-top', {
  //       transform: 'translate3d(-10%, -10% , 0)',
  //       duration: 30,
  //     })
  //     .from('.js-hand-bottom', {
  //       transform: 'translate3d(10%, 10% , 0)',
  //       duration: 20,
  //     })
  // } else {
  //   handTopBottom
  //     .from('.js-hand-top', {
  //       transform: 'translate3d(-80%, -80% , 0)',
  //       delay: 20,
  //       duration: 10,
  //     })
  //     .from('.js-hand-bottom', {
  //       transform: 'translate3d(50%, 50% , 0)',
  //       delay: 8,
  //       duration: 10,
  //     })
  // }

  if ($window.height() > 1200) {
    var handText = 'center'
  } else {
    var handText = '600px'
  }

  // End text
  gsap.from('.js-hand-end-text', {
    scrollTrigger: {
      trigger: '.section--offer-4',
      start: 'top ' + handText, // when the top of the trigger hits the top of the viewport
      end: '+=100', // end after scrolling 500px beyond the start
      scrub: 1, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
    },
    y: '100px',
    opacity: 0,
  })

  // if ($window.height() > 1200) {
  //   var slideShowSmall = 'center'
  // } else {
  //   var slideShowSmall = '1000px'
  // }

  // SlideShow Small
  // if ($window.width() > bpSmall) {
  //   gsap.from('.js-slideshow-small', {
  //     scrollTrigger: {
  //       trigger: '.js-slideshow-small',
  //       start: 'top ' + slideShowSmall, // when the top of the trigger hits the top of the viewport
  //       end: '+=100', // end after scrolling 500px beyond the start
  //       scrub: 1, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
  //     },
  //     y: '100px',
  //     opacity: 0,
  //   })
  // }
}
